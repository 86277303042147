#website-footer {
    color: #4c728a;
    font-family: "Oswald-Light";
    font-size: 10px;
    padding: 0 0 20px 0;
    @include mq('md') {
        padding: 60px 0 20px 0;
    }
    float: left;
    width: 100%;
    ul {
        list-style: none;
        li {
            padding: 1px 0;
        }
    }
    .logo-container {
        img {
            display: block;
            margin: 20px 0;
            @include mq('md') {
                margin: 0 auto 60px auto;
            }
        }
    }
    .copyright {
        padding-top: 30px;
        font-family: "Oswald-Light";
    }
    
    .copyright-code {
        margin-left: 24px;
    }

}
