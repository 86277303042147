@font-face {
    font-family: 'Oswald-Light';
    src: url('../fonts/oswald-light-webfont.eot');
    src: url('../fonts/oswald-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oswald-light-webfont.woff2') format('woff2'),
         url('../fonts/oswald-light-webfont.woff') format('woff'),
         url('../fonts/oswald-light-webfont.ttf') format('truetype'),
         url('../fonts/oswald-light-webfont.svg#oswaldlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald-Bold';
    src: url('../fonts/oswald-bold-webfont.eot');
    src: url('../fonts/oswald-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oswald-bold-webfont.woff2') format('woff2'),
         url('../fonts/oswald-bold-webfont.woff') format('woff'),
         url('../fonts/oswald-bold-webfont.ttf') format('truetype'),
         url('../fonts/oswald-bold-webfont.svg#oswaldbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
