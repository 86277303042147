
#causes-popup {
    @include generalTransition;
    transition: right 0.5s ease-out;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    color: #00b0b3;
    overflow-x: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 998;
    padding-top: 20px;
    padding-bottom: 56px;

    @include mq('tablet') {
        padding-top: 50px;
        padding-bottom: 50px;
        height: 100%;
    }

    &.active {
        right: 0;
    }

    .container {
        position: relative;
    }

    .close-popup {
        position: absolute;
        top: -20px;
        right: 0;
        width: 100%;
        height: 50px;
        cursor: pointer;
        background: #ffffff;
        padding: 10px 0;
        z-index: 99999;
        text-align: right;

        @include mq('phone', max) {
            padding: 10px;
            height: 50px;
        }
        @include mq('tablet') {
            width: 90px;
            height: 40px;
        }

        &:hover {
            .fill-color-purple {
                fill: $buttonHover;
            }
            .text {
                color: $buttonHover;
            }
        }

        .text {
            @include generalTransition;
            display: inline-block;
            margin-right: 4px;
            color: $colorPurple;
            font-size: 15px;
            letter-spacing: 0.3px;
            vertical-align: middle;

            @include mq('tablet') {
                margin-right: 10px;
                font-size: 20px;
                letter-spacing: 0.4px;
            }
        }

        .icon {
            display: inline-block;
            width: 26px;
            height: 26px;
            vertical-align: middle;

            @include mq('tablet') {
                width: 35px;
                height: 35px;
            }

            svg {
                width: 26px;
                height: 26px;

                @include mq('tablet') {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }




    .flex-wrapper {
        padding-top: 50px;
        padding-bottom: 56px;

        .image-wrapper {
            .image {
                display: block;
                height: 100%;
                margin: 0 auto;
            }
        }

        .text-box {
            margin-top: 90px;

            @include mq('md') {
                margin-top: 0;
                margin-left: 110px;
            }
            @include mq('tablet') {
                padding: 0 20px;
            }

            .heading {
//                margin-top: 417px;
                text-align: center;
                @include mq('tablet', max) {
//                    max-width: 240px;

                }
            }
            .description {
                text-align: left;
                @include mq('tablet') {
                    font-size: 24px;
                }
            }
        }
    }
}

.IE {
    #causes-popup {
        .flex-wrapper {
            .switch-container {
                width: 200px;
            }
            .text-box {
                margin-left: 300px;
            }
        }
    }
}

.girl-graph-container,
.boy-graph-container {
    @include mq('tablet-small', min) {
        max-height: 580px;
        width: 280px;
    }
    svg {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
    /*--- Boy SVG CSS -- */

    .boy {
        /*opacity : 0;*/
        display: none;
    }
    cursor: pointer;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
.switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .desc {
        height: 34px;
        line-height: 34px;
        color: #A70063;
        margin: 0 10px;
        font-weight: bold;
    }
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #A70063;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #A70063;
}

input:focus + .slider {
    box-shadow: 0 0 1px #A70063;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


    /*---SVG CSS--- */
    #causes-popup {

        #brain-circles .brain-skull {
            margin-top: 100px;
            margin-left: 200px;
            position: relative;
            width: 314px;
            height: 390px;
        }
        #brain-circles .brain-skull img {
            position: relative;
            z-index: 3;
        }

        #brain-circles .circle-holder {
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 9;
            width: 90px;
            height: 90px;
            top: 128px;
            right: 78px;
        }

        #brain-circles .brain-skull .white-circle:nth-child(2) {
            left: 15px;
        }
        #brain-circles .brain-skull .white-circle:first-child {
            left: 30px;
        }
        #brain-circles .brain-skull .white-circle {
            border: 1px solid #FFFFFF;
            border-radius: 50%;
            transition: opacity 0.6s ease;
            position: absolute;
            opacity: 0;
        }

        #girl-boy {
            padding-left: 40px;
            position:relative;
        }

        .girl-graph-container, .boy-graph-container {
            width: 200px;
            height: 400px;
            margin-top: 20px;
            position: absolute;
        }

        .girl-graph-container {
            /*    display:none;*/
        }


        .boy-graph-container {
            opacity:0;
        }


        /* The switch - the box around the slider */
        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
        }
        .switch-container {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .switch-container .desc {
            height: 34px;
            line-height: 34px;
            color: #A70063;
            margin: 0 10px;
            font-weight: bold;
            cursor: pointer;
            font-size: 28px;
        }


        /*--CSS for NO-SVG animation-*/
        .girl-image, .boy-image {
            width: 100%;
        }


        .blue-arrow-body, .blue-arrow-body-boy {
            width: 2px;
            height: 0;
            margin-top: 38.6%;
            position: absolute;
            margin-left: 49.08%;
            z-index:22;
            background-color: #01acaf;
        }


        .blue-arrow-tip, .blue-arrow-tip-boy {

            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #01acaf;
            margin-left: 45.6%;
            display:none;
            margin-top: 147px;
            position: absolute;
            z-index: 22;
        }

        .first-red-arrow {
            position: absolute;
            bottom: 0;
            left: 120px;
        }

        .red-arrow-tip-1 {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #a60062;
            position: relative;
            z-index:4;
        }


        .center-circle-girl, .center-circle-boy {
            transition:all .6s ease-in-out;
            -webkit-filter: blur(9px);
            filter: blur(9px);
            width: 35px;
            height: 35px;
            margin-left: 41.5%;
            margin-top: -35%;
            border-radius: 50%;
            background-color: #a60062;
            position:absolute;
            transform:scale(0);
            z-index: 23;

        }


        .fallback-center-circle, .fallback-center-circle-boy {
            transform: scale(0);
            position: absolute;
            transition: .5s;
            width: 30px;
            height: 30px;
            margin-left:42%;
            margin-top: -36%;
            z-index: 25;
        }


        .fallback-center-image, .fallback-center-image-boy {
            width: 30px;
            height: 30px;
            position:absolute;
            z-index:15;
        }


        .fallback-circle, .fallback-circle-boy {
            position: absolute;
            z-index: 15;

        }

        /* Text boxes CSS */


        .brain-text, .left-text, .right-text, .brain-text-boy, .left-text-boy, .right-text-boy{
            opacity:0;
            transition: .2s ease-in;
            /*    display:none;*/


        }


        .circle-blur-1, .circle-blur-2, .circle-blur-3, .circle-blur-4, .circle-blur-1-boy, .circle-blur-2-boy, .circle-blur-3-boy, .circle-blur-4-boy {
            transition:all .6s ease-in-out;
            -webkit-filter: blur(3.5px);
            filter: blur(3.5px);
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #a60062;
            position:absolute;
            z-index: 21;
            transform:scale(0);
        }


        .circle-blur-1-boy, .circle-blur-4-boy, .circle-blur-1, .circle-blur-4{
            margin-top: -120%;
        }

        .circle-blur-2-boy, .circle-blur-3-boy {
            margin-top: -175%;
        }

        .circle-blur-2, .circle-blur-3{
                margin-top: -135%;
        }

        .circle-blur-2 {
            margin-left: 38%;

        }

        .circle-blur-1-boy, .circle-blur-1 {
            margin-left: 22%;
        }


        .circle-blur-2-boy {
            margin-left: 37%;
        }

        .circle-blur-3-boy, .circle-blur-3 {
            margin-left: 54.5%;
        }

        .circle-blur-4-boy, .circle-blur-4 {
            margin-left: 69%;
        }







        .fallback-circle, .fallback-circle-boy  {
            transform:scale(0);
            position: absolute;
            z-index: 26;
            transition:all .6s ease-in-out;
        }

        .fallback-circle-blur-1 img, .fallback-circle-blur-1-boy img, .fallback-circle-blur-2 img, .fallback-circle-blur-2-boy img, .fallback-circle-blur-3 img, .fallback-circle-blur-3-boy img, .fallback-circle-blur-4 img , .fallback-circle-blur-4-boy img {
            width: 25px;
            height: 25px;

        }



        .fallback-circle-blur-1 {
            margin-top: -128%;
            margin-left:20%;
        }

        .fallback-circle-blur-2 {
            margin-top: -143%;
            margin-left: 34%;


        }
        .fallback-circle-blur-3 {
            margin-top: -143%;
            margin-left: 52.5%;


        }
        .fallback-circle-blur-4 {
            margin-top: -128%;
            margin-left: 66%;
        }


        .fallback-circle-blur-1-boy {
            margin-top: -128%;
            margin-left:20%;
        }

        .fallback-circle-blur-2-boy {
            margin-top: -183%;
            margin-left: 34%;

        }

        .fallback-circle-blur-3-boy {
            margin-top: -183%;
            margin-left: 52.5%;

        }

        .fallback-circle-blur-4-boy {
            margin-top: -128%;
            margin-left: 66%;

        }

        .fallback-small-circle, .fallback-small-circle-boy {
            transition:all .6s ease-in-out;
        }










        .arrow-blue-top, .arrow-blue-left, .arrow-blue-right, .arrow-red-left, .arrow-blue-top-boy, .arrow-blue-left-boy, .arrow-blue-right-boy, .arrow-red-left-boy  {
            position:absolute;
            width: 0;
            height: 0;
            opacity: inherit;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            z-index: 20;
            transition: all .1s ease-in;

        }



        .arrow-blue-top, .arrow-blue-top-boy {
            margin-top: 28%;
            margin-left: 55.5%;
            border-right:17px solid #01acaf;
        }



        .arrow-blue-left, .arrow-blue-left-boy{
            border-left: 17px solid #01acaf;
            margin-top: 72%;
            margin-left: 39%;
        }



        .arrow-blue-right, .arrow-blue-right-boy {
            margin-left: 51.5%;
            border-right:17px solid #01acaf;
        }

        .arrow-blue-right {
           margin-top: 72%;
        }

        .arrow-blue-right-boy, .arrow-blue-left-boy {
                margin-top: 77%;
        }


        .first-text-blue, .left-text-blue, .right-text-blue, .left-text-red , .first-text-blue-boy, .left-text-blue-boy, .right-text-blue-boy, .left-text-red-boy {
            padding: 4px;
            opacity: inherit;
            background-color: #01acaf;
            color: #fefefe;
            position: absolute;
            text-align: center;
            font-size: 15px;
            z-index:22;
            transition:all .11s ease-in;

        }


        .left-text-red, .arrow-red-left, .left-text-red-boy, .arrow-red-left-boy {
            opacity:0;
            transition:all .11s ease-in;
            z-index:25;
            position: absolute;
        }


        .left-text-red, .left-text-red-boy {

            margin-top: 145%;
            background-color: #a60062;

        }

        .left-text-red {
            margin-left: -1%;
        }

        .left-text-red-boy {
            margin-left: -12.5%;
        }

        .arrow-red-left, .arrow-red-left-boy{
            /*    opacity: inherit;*/
            border-left: 17px solid #a60062;
            margin-top: 146%;
            margin-left: 20%;
        }


        .first-text-blue, .first-text-blue-boy {
            margin-left: 61%;
            margin-top: 22%;
            width: 42%;
        }



        .left-text-blue, .left-text-blue-boy {

            margin-left: 9%;
            width: 33%;
        }

        .left-text-blue {
                margin-top: 65.5%;
        }

        .right-text-blue, .right-text-blue-boy  {
            margin-left: 57%;
            width: 52%;


        }

        .right-text-blue {
            margin-top: 65.5%;
        }

        .right-text-blue-boy, .left-text-blue-boy {
            margin-top: 140px;
        }


        .arrow-cover, .arrow-cover-boy {
            height: 347px;
            width: 49.1%;
            margin-left: 25.8%;
            margin-top: -406px;
            background-color: #d0dd28;
            position: absolute;
            z-index: 3;
            transition: 1.2s linear;

        }

        .left-red-arrow-body, .left-red-arrow-body-boy {
            position: absolute;
            width:10px; height:500px;
        }

        .arrows-svg, .arrows-svg-boy {
            margin-top: 30%;
            margin-left: 5.8%;
            position: absolute;
            z-index: 20;
        }

        .arrow-1, .arrow-2 ,.arrow-3, .arrow-4 , .arrow-5, .arrow-5 {
            opacity: 0;
        }

        .cls-10 {
            display: none;
        }


        #arrow-head-1, #arrow-head-2, #arrow-head-3, #arrow-head-4, #arrow-head-1-boy, #arrow-head-2-boy, #arrow-head-3-boy, #arrow-head-4-boy {
            visibility: hidden;
            position:absolute;
            z-index:35;
        }

        img.line-1, .img-line-2, .img-line-3, .img-line-4 {
            position: absolute;
        }


        img.line-1, img.line-1-boy {
            margin-top: 121%;
            margin-left: 26%;
            height: 41%;
            position: absolute;
            z-index: 19;

        }

        img.line-2, img.line-2-boy {
            position: absolute;

        }

        img.line-2 {
            margin-left: 40%;
        }


        img.line-2-boy, img.line-3-boy {
            height: 68%;
            margin-top: 66%;

        }

        img.line-2-boy {
            margin-left: 39%;
        }



        img.line-3, img.line-3-boy {

            margin-left: 52%;
            position: absolute;
            z-index: 19;
        }

        img.line-3 {
            margin-left:53%;
        }

        img.line-2, img.line-3 {
                height: 49%;
    margin-top: 106%;
        }


        img.line-4, img.line-4-boy {
            margin-top: 121%;
            margin-left: 53.7%;
            height: 41%;
            position: absolute;
            z-index: 19;
        }

        .image-cover, .image-cover-boy{
            background-color: #d0dd28;
            /*        background-color:red;*/
            position: absolute;
            z-index: 19;
            height: 63%;
            width: 48%;
            margin-left: 26%;
            margin-top: -152%;

        }

        .image-cover-extra, .image-cover-extra-boy{
            background-color: #d0dd28;
            /*    background-color: red;*/
            position: absolute;
            z-index: 19;
            height: 8.2%;
            width: 25%;
            opacity: 1;
            margin-left: 37.5%;
            margin-top: -165.5%;

        }



        /* Hide default HTML checkbox */
        .switch input {display:none;}

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #A70063;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked + .slider {
            background-color: #A70063;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #A70063;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }

    }

.iOSSafari {
    #causes-popup .flex-wrapper .text-box .description {
        padding-bottom: 60px;
    }
}