#sections section {
    &#diagnose-section {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        background: #0885b7;

        .top-diagnose-wrapper {
            @include generalTransition;
            background-color: #0885b7;
            position: relative;
            height: 100px;
            z-index: 9;

            @include mq('tablet') {
                visibility: hidden;
                opacity: 0;
                background-color: transparent;
                height: 0;
            }

            &.inview {
                opacity: 1;
                visibility: visible;
            }

            .inner {
                @include generalTransition;
                background-color: #00b0b3;
                box-shadow: 0 0 15.5px 0.5px rgba(0, 103, 108, 0.61);
                position: absolute;
                top: 10px;
                left: 0;
                z-index: 1;
                padding: 15px 15px;
                text-align: center;

                @include mq('tablet') {
                    max-width: 630px;
                    padding: 15px 30px;
                    top: 60px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }

                @include mq('md') {
                    top: 90px;
                    padding: 15px 36px;
                }
                @include mq('tablet', max) {
                    width: 100%;
                }

                @media only screen and (min-width: 768px) {
                    top: 120px;
                }
                @media only screen and (min-width: 1024px) {
                    top: 90px;
                }
                .heading {
                    margin-bottom: 5px;
                }
            }
        }

        .container {
            @include mq('tablet', max) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                .box {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }

        .flex-wrapper {
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            height: 100%;

            .box {
                height: 100%;
                width: 100%;
                color: #ffffff;
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 50%;

                @include mq('tablet') {
                    width: 50%;
                    padding-left: 40px;
                }

                .table-cell {
                    @include mq('tablet') {
                        padding-top: 40%;
                    }
                }

                &.left-side {
                    padding-top: 36px;
                    padding-bottom: 32px;
                    background-color: #ef6399;

                    @include mq('tablet') {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    @include mq('lg', min) {
                        padding-right: 40px;
                    }
                    @include mq('phone-wide', max) {
                        padding-top: 20%;
                    }
                    @include mq('phone-small', max) {
                        padding-top: 30%;
                    }

                    .container-half {
                        margin: 0;
                        float: right;

                    }
                }

                &.right-side {
                    padding-top: 37px;
                    padding-bottom: 31px;
                    background-color: #b9c524;

                    @include mq('tablet') {
                        padding-right: 0;
                        padding-left: 0;
                    }

                    @include mq('lg') {
                        padding-left: 50px;
                    }

                    .container-half {
                        margin: 0;
                        float: left;
                    }
                }

                .heading {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    svg {
                        @include mq('lg', min) {
                            margin-right: 30px;
                        }
                        @include mq('lg', max) {
                            max-height: 80px;
                        }
                    }

                    .superscript {
                        vertical-align: text-top;
                    }
                }

                .description {
                    &.flex {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding-left: 27px;
                        ul {
                            &.left {
                                @include mq('tablet', max) {
                                    padding-left: 40px;
                                }
                            }
                            &.right {
                                padding-left: 15px;
                            }
                        }
                    }
                }

                ul {
                    list-style: none;
                    &:first-child {
                        @include mq('lg') {
                            margin-left: -27px;
                        }
                        li {
                            max-width: 260px;
                        }
                    }

                    li {
                        margin-top: 6px;
                        list-style-type: none;
                        padding-left: 10px;
                        text-indent: -10px;
                        @include mq('tablet-wide', max) {
                            margin-top: 2px;
                        }
                        &:before {
                            content: "•";
                            font-size: 30px;
                            vertical-align: top;
                            position: relative;
                            left: -3px;
                            @include mq('tablet-wide', max) {
                                top: -3px;
                            }
                        }
                    }
                }
            }
        }
    }
    .container-half {
        @include mq('tablet', max) {
            padding-left: 30%;
            margin-right: 0;
        }
        .description {
            &:not(.flex) {
                ul {
                    @include mq('lg', max) {
                        padding-left: 10%;
                    }
                }
            }
        }
    }
    
    
    .logos-holder {
        padding: 20px;
        margin-top: 20px;
        @include mq('lg', max) {
            padding-left: 10%;
        }
        div {

            @include mq('phone', max) {
                margin-bottom: 10px;
            }
            img {
                float: left;
                width: 100%;
                &:not(:last-child) {
                    margin-right: 20px;
                }
                &.second,
                &.third {
                    position: relative;
                }
                &.first {
                     @media only screen and (max-width: 500px) {
                       max-width: 147%;
                         width: 147%;
                       margin-left: -46%;
                }
                     max-width: 100%;
                }
                &.second {
                    max-width: 137px;
                    top: -24px;
                        margin-left: 24%;

                }
                &.third {
                    max-width: 148px;
                    top: -18px;
                    @include mq('phone', max) {
                        margin: 0 auto;
                    }
                }
                &.last {
                      @media only screen and (max-width: 500px) {
                       max-width: 130%;
                         width: 130%;
                        margin-left: -20%;
                }
                    
                }
            }
            &.col-xs-12 {
                a {
                    @include mq('lg', max) {
                        display: block;
                        margin: 0 auto;
                        width: 30%;
                    }
                    @include mq('phablet', max) {
                        width: 50%;
                    }
                }
            }
            &.middle {
                a {
                    float: right;
                    img {
                        max-width: 150px;
                    }
                }
            }
            &.last {
                @include mq('lg') {
                    a {
                        float: right;
                    }
                }
            }
        }
    }
    .container {
        &.container-half {
            @include mq('phone') {
                width: 375px;
            }
            @include mq('tablet') {
                width: 100%;
                max-width: 540px;
            }
            @include mq('lg') {
                width: 90%;
                max-width: 540px;
            }
            @include mq('lg') {
                width: 560px;
                max-width: 100%;
            }
        }
        
    }
         
   
}

 
.IE {
    #sections {
        section#diagnose-section {
            .flex-wrapper .box ul li {
                width: 98%;
            }
            .top-diagnose-wrapper {
                max-width: 700px;
                width: 100%;
                margin: 0 auto;
            }
        }
    }
}

.iOSSafari {
    #sections section#diagnose-section .flex-wrapper {
        display: block;
    }
    #sections section#diagnose-section .flex-wrapper .box.right-side {
        @include mq('tablet') {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    #sections section#diagnose-section .flex-wrapper .box ul li:before {
        @include mq('tablet-wide', max) {
            top: -3px;
        }
        @include mq('phone', max) {
            top: -5px;
        }
    }
}

