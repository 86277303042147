#sections section {
    &#treat-section {
        background: #0593b6;
        background: linear-gradient(#0593b6, #0885b7);

        .heading {
            text-align: center;
        }

        .flex-wrapper {
            .box {
                &.left-side {
                    margin-bottom: 26px;

                    @include mq('tablet') {
                        margin-bottom: 40px;
                    }

                    @include mq('md') {
                        margin-bottom: 0;
                    }

                    @include mq('lg') {
                        margin-left: 6%;
                    }

                    .icon-stick {
                        width: 228px;
                        height: 170px;

                        @include mq('tablet') {
                            width: 295px;
                            height: 220px;
                        }

                        @include mq('md') {
                            width: 335px;
                            height: 250px;
                        }

                        svg {
                            width: 228px;
                            height: 170px;

                            @include mq('tablet') {
                                width: 295px;
                                height: 220px;
                            }

                            @include mq('md') {
                                width: 335px;
                                height: 250px;
                            }
                        }
                    }
                }

                &.right-side {
                    @include mq('tablet') {
                        margin-left: 40px;
                    }

                    @include mq('md') {
                        margin-left: 44px;
                    }

                    .heading {
                        text-align: left;
                    }

                    .description {
                        @media only screen and (min-width: 767px) and (max-width: 991px) {
                            width: 72%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }



    #people-box {
        max-width: 430px;
        height: 260px;
        float: left;
        display: flex;
        align-items: flex-end;

        .main-container {
            margin-top: 200px;
        }

        .half-holder {
            position: relative;
            height: 260px;
            float: left;
            display: flex;
            align-items: flex-end;
        }

        .small .progressive-rise {
            height: 190px;
            @include mq('tablet', max) {
                height: 150px!important;
            }
        }

        .large .progressive-rise {
            height: 258px;
            @include mq('tablet', max) {
                height: 214px!important;
            }
        }

        .progressive-rise {
            width: 34px;
            transition: all 0.6s ease;
            transform: translateY(50%);
            img {
                margin: 0 auto;
                &:last-child {
                    position: relative;
                    bottom: 33px;
                }
            }
            &.animate {
                transform: translateY(0);
                .arrow-body {
                    opacity: 1;
                }
            }
        }


        .stick,
        .arrows-holder {
            float: left;
        }
        .arrows-holder {
            width: 34px;
        }
        .arrow-body {
            background-color: #dca510;
            width: 8px;
            height: 10px;
            margin: 0 auto;
            display: block;
            transition: height 0.6s ease, opacity 0.4s ease;
            position: relative;
            top: -15px;
            opacity: 0;
        }
        .progressive-rise {
            img {
                opacity: 0;
                transition: opacity 0.4s ease;
            }
            &.animate {
                img {
                    opacity: 1;
                }
            }
        }
        .small {
            .progressive-rise.animate {
                .arrow-body {
                    height: 160px;
                    @include mq('tablet', max) {
                        height: 100px !important;
                    }
                }
            }
            .stick {
                img {
                    height: 190px;
                    width: 120px;
                }
            }
        }

        .large {
            .progressive-rise.animate {
                .arrow-body {
                    height: 220px;
                    @include mq('tablet', max) {
                        height: 168px!important;
                    }
                }
            }
            .stick>img {
                height: 250px;
                width: 180px;
            }

        }
    }
}

.Mac-OS.Safari,
.Chrome.Mac-OS {
    #sections section#treat-section {
        @media only screen and (min-width: 768px) and (max-width: 990px) {
            min-height: 100vh;
            height: initial;
        }
    }
}

.iOSSafari {
    #sections section #people-box .small .progressive-rise {
        height: 176px;
    }
    #sections section #people-box .small .progressive-rise.animate .arrow-body {
        height: 150px;
    }
    #sections section #people-box .large .progressive-rise {
        height: 246px;
    }
    #sections section #people-box .large .progressive-rise.animate .arrow-body {
        height: 230px;
    }
}

.IE {
    #sections section#treat-section .flex-wrapper .box.left-side {
        margin-left: 0;
    }
}
