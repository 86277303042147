@import 'bootstrap';

@import 'reset';

@import 'variables';
@import 'mixins';

@import 'fonts';
@import 'general';

@import 'general/header';
@import 'general/footer';

@import 'general/module/hero-image';

@import 'sections/facts-section';
@import 'sections/causes-section';
@import 'sections/treat-section';
@import 'sections/diagnose-section';
@import 'sections/how-treatment-works';
@import 'sections/find-endo';
@import 'sections/discussion-guide';
@import 'sections/what-causes-panel';

@import 'magnific-popup/settings';
@import 'magnific-popup/main';
