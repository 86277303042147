html {
    position: relative;
}

body {
    background-color: #ffffff;
    color: #ffffff;
    font-size: 14px;
    font-family: "Oswald-Light", Arial, sans-serif;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    padding-top: 0;
    min-height: 100%;
    overflow-x: hidden;
    transition: opacity 0.6s ease;
    .transparent {
        opacity: 0;
    }

    @include mq('lg') {
        font-size: 28px;
    }

    &.menu-in {
        @include mq('tablet', max) {
            height: 100%;
            overflow: hidden;
        }
    }

    &.no-scroll {
        height: 100%;
        overflow: hidden;
    }

    &.popup-in {
        #progress-wrapper {
            z-index: -1;
        }
        #website-header {
            .menu-trigger-wrapper {
                z-index: -1;
            }
        }
    }
}
.container {
    min-width: 320px;

    &.container-half {
        @include mq('phone') {
            width: 375px;
        }

        @include mq('tablet') {
            width: 100%;
        }

        @include mq('desktop') {
            width: 542px ;
        }
        @include mq('desktop-wide') {
            width: 585px;
        }
    }
}

a {
    @include generalTransition;
    color: #000000;
    line-height: 1.3;
    outline: none;

    &:hover,
    &:focus,
    &:active {
        color: #000000;
        text-decoration: none;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Oswald-Bold";
    line-height: 1.3;
}

.h1-size {
    font-size: 60px;

    @include mq('tablet') {
        font-size: 70px;
    }

    @include mq('md') {
        font-size: 80px;
    }

    .superscript {
        font-size: 21px;
        position: relative;
        top: -24px;

        @include mq('md') {
            font-size: 27px;
            position: relative;
            top: -30px;
        }
    }
}

.h2-size {
    font-size: 40px;

    @include mq('tablet') {
        font-size: 60px;
    }

    @include mq('md') {
        font-size: 80px;
    }

    .superscript {
        font-size: 24px;

        @include mq('tablet') {
            font-size: 36px;
        }

        @include mq('md') {
            font-size: 40px;
        }
    }
}

.h3-size {
    font-size: 21px;
    &.mobile-big {
        @include mq('tablet', max) {
            font-size: 26px;
        }
    }

    @include mq('tablet') {
        font-size: 30px;
    }

    @include mq('md') {
        font-size: 34px;
    }

    .superscript {
        font-size: 14px;

        @include mq('tablet') {
            font-size: 18px;
        }

        @include mq('md') {
            font-size: 20px;
        }
    }
}

.h4-size {
    font-size: 21px;

    @include mq('tablet') {
        font-size: 24px;
    }

    @include mq('md') {
        font-size: 28px;
    }

    .superscript {
        font-size: 12px;

        @include mq('tablet') {
            font-size: 14px;
        }

        @include mq('md') {
            font-size: 16px;
        }
    }
}

.h5-size {
    font-size: 15px;

    @include mq('tablet') {
        font-size: 18px;
    }

    @include mq('md') {
        font-size: 20px;
    }
}

.h6-size {

}

.font-bold {
    font-family: "Oswald-Bold";
}

.font-light {
    font-family: "Oswald-Light";
}

p {
    margin-bottom: 10px;
}

button,
textarea,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="button"],
input[type="submit"] {
    appearance: none;
    border-radius: 0;
    border: 0 none;
    background: transparent;
    outline: none;
}

.checkbox,
.radio {
    margin-top: 5px;
}

img  {
    @include generalTransition;
    max-width: 100%;
    height: auto;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

.table {
    display: table;
    width: 100%;
    height: 100%;
    margin: 0;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;

    &.full {
        width: 100%;
        height: 100%;
    }
}

.full-height {
    height: 100%;
}

video::-webkit-media-controls {
    display: none !important;
}

.mobile-hide-br {
    @include mq('tablet', max) {
        br {
            display: none;
        }
    }
}

.superscript {
    vertical-align: super;
    font-size: 10px;
}

svg {
    .fill-color-white {
        @include generalTransition;
        fill: #ffffff;
    }

    .fill-color-lime {
        @include generalTransition;
        fill: $colorLime;
    }

    .fill-color-pink {
        @include generalTransition;
        fill: $colorPink;
    }

    .fill-color-purple {
        @include generalTransition;
        fill: $colorPurple;
    }
}

.color-lime {
    color: $colorLime;
}

.bg-color-lime {
    background-color: $colorLime;
}

.color-pink {
    color: $colorPink;
}

.bg-color-pink {
    background-color: $colorPink;
}

.color-purple {
    color: $colorPurple;
}

.bg-color-purple {
    background-color: $colorPurple;
}

.small-padding {
    padding: 5px 5px 3px 5px;
}

.heading {
    margin-bottom: 16px;
}
/*********************************************************************************
 * Keyframes
 ********************************************************************************/
@keyframes bounce {
    0%, 8%, 16% {
        transform: translateY(0);
    }
    4% {
        transform: translateY(-30px);
    }
    12% {
        transform: translateY(-15px);
    }
}

.bounce {
    animation: bounce 6s ease 0s infinite normal none running;
}

/*********************************************************************************
 * PROGRESS BAR
 ********************************************************************************/
#progress-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 10px;
    border: none;
    background-color: transparent;
    will-change: transform;
    z-index: 9999;

    .progress-line {
        @include generalTransition;
        will-change: transform;
        display: block;
        height: 10px;
        width: 0;
        background-color: #ffffff;
    }
}

/*********************************************************************************
 * Navigation Arrow Down
 ********************************************************************************/
.navigation-arrow-down {
    @include generalTransition;
    width: 28px;
    height: 32px;
    margin: 40px auto auto;
    cursor: pointer;
    user-select: none; 
    z-index: 98;

    @include mq('md', max) {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
    }

    @include mq('tablet') {
        margin: auto;
    }

    @include mq('md') {
        position: relative;
        margin-top: 80px;
    }

    svg {
        width: 28px;
        height: 32px;
    }
}

/*********************************************************************************
 * Top section
 ********************************************************************************/
#top-section {
    width: 100%;
    height: 100vh;
    position: relative;
    top: 0;
    z-index: 10;
    will-change: transform;

    @include mq('tablet-wide') {
        @media only screen and (min-height: 320px) and (max-height: 579px) {
            height: 560px!important;
        }
    }
}

#sections {
    /*@include generalTransition;*/
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateZ(0);

    &.fixed {
        position: fixed;
    }
    section {
        width: 100%;
        height: auto;
        position: relative;
        background-color: transparent;
        overflow: hidden;
        padding-top: 50px;
        padding-bottom: 35px;
        color: #ffffff;

        @include mq('tablet') {
            padding-top: 50px;
            padding-bottom: 50px;
            height: 100vh;
        }

        @include mq('md') {
            height: 100vh;
            padding-top: 0;
            padding-bottom: 0;
        }

        .table {
            @include mq('tablet', max) {
                display: block;
                height: auto;
            }

            .table-cell {
                @include mq('tablet', max) {
                    display: block;
                    width: 100%;
                }
            }
        }

        .flex-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @include mq('md') {
                flex-wrap: nowrap;
//                margin-top: 25vh;
//                align-items: center;
                &.align-items-top {
//                    align-items: flex-start;
                
                }
            }
        }

        .float-wrapper {
            display: block;
        }
    }
    .draw-me {
        opacity: 0;
    }
}

.white-popup {
    position: relative;
    background: #ffffff;
    padding: 30px 15px;
    width: 94%;
    max-width: 580px;
    margin: 20px auto;

    .mfp-close {
        display: none;
    }

    @include mq('tablet') {
        padding: 30px 30px 40px 30px;
    }

    p {
        font-size: 34px;
        font-weight: 300;
        color: #00b0b3;
        line-height: 40px;
        @include mq('tablet', max) {
            font-size: 26px;
            line-height: 29px;
        }
        @include mq('tablet-small', max) {
            font-size: 23px;
            line-height: 26px;
        }
        @include mq('phone', max) {
            font-size: 21px;
            line-height: 24px;
        }
    }
    .options {
        margin-top: 20px;

        @include mq('tablet') {
            margin-top: 40px;
        }

        a {
            font-family: "Oswald-Bold";
            height: 40px;
            line-height: 40px;
            border: 1px solid $button;
            border-radius: 50px;
            width: 100%;
            text-align: center;
            vertical-align: middle;
            font-size: 16px;

            @include mq('tablet') {
                font-size: 20px;
                width: 46%;
                height: 50px;
                line-height: 50px;
            }

            &.no {
                float: left;
                margin-right: 2%;
                color: $button;

                &:hover,
                &:focus,
                &:active {
                    background-color: $buttonHover;
                    border: 1px solid $buttonHover;
                    color: #ffffff;
                }
            }

            &.yes {
                float: right;
                margin-left: 2%;
                background-color: $button;
                color: #ffffff;
                margin-bottom: 20px;

                @include mq('tablet') {
                    margin-bottom: 0;
                }

                &:hover,
                &:focus,
                &:active {
                    background-color: $buttonHover;
                    border: 1px solid $buttonHover;
                }
            }
        }
    }
}

/* overlay at start */
.mfp-fade.mfp-bg {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}


.open-popup-link {
    display: inline-block;
    border-radius: 25px;
    background-color: #ffffff;
    font-size: 20px;
    letter-spacing: .4px;
    text-align: center;
    font-family: "Oswald-Bold";
    color: $button;
    padding: 10px 20px 6px;
    vertical-align: middle;

    @include mq('tablet') {
        padding: 15px 20px 10px;
    }

    @include mq('lg') {
        padding: 15px 20px;
    }

    &:focus,
    &:visited{
        color: $button;
    }

    &:hover {
        background-color: $buttonHover;
        color: #ffffff;
    }

    &.no-link {
        background: none;
        padding: 0;
    }
}

.transparent {
    opacity: 0;
}

.pink-button {
    display: block;
    width: 80%;
    background-color: #a70063;
    color: #fff;
    font-size: 20px;
    font-family: Oswald-Bold;
    border-radius: 50px;
    margin: 30px auto;
    height: 50px;
    line-height: 50px;
    &:focus,
    &:visited{
        color: #FFFFFF;
    }
    &:hover {
        background-color: $buttonHover;
        color: #FFF;
    }
    @include mq('phone', max) {
        width: 94%;
    }
}

.button-wrapper {
    margin-top: 20px;
    text-align: center;

    @include mq('tablet') {
        text-align: left;
    }


    .button {
        display: inline-block;
        margin: 0 auto;
        height: 42px;
        line-height: 42px;
        border-radius: 20px;
        background-color: rgba(#000000, 0.11);
        padding: 0 16px 0 8px;
        cursor: pointer;
        @include mq('tablet') {
            height: 55px;
            line-height: 55px;
            border-radius: 25px;
            padding: 0 21px 0 10px;
        }

        &:hover {
            .fill-color-purple {
                fill: $buttonHover;
            }
        }

        .icon-plus {
            display: inline-block;
            margin-right: 4px;
            position: relative;
            width: 26px;
            height: 26px;
            /*background-color: $colorPurple;
            border-radius: 50%;*/
            text-align: center;
            vertical-align: middle;
            margin-top: -3px;

            @include mq('tablet') {
                width: 35px;
                height: 35px;
                margin-right: 5px;
                margin-top: -5px;
            }

            svg {
                width: 26px;
                height: 26px;

                @include mq('tablet') {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        .text {
            vertical-align: middle;
            letter-spacing: 0.6px;

            @include mq('tablet') {
                letter-spacing: 0.8px;
            }
            @media screen and (max-width: 366px){
                font-size: 12px;
            }
        }
    }
}

.IE {
    #sections {
        .draw-me {
            opacity: 1;
        }
        section {
            &:not(.first-section) {
                .flex-wrapper {
                    display: inline-block;
                    width: 100%;
                    .box {
                        &.left-side {
                            float: left;
                            width: 34%;
                        }

                        &.right-side {
                            float: right;
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}


.Mac-OS.Chrome,
.iOS.iOSSafari ,
.Mac-OS.Safari {
    .button-wrapper .button {
        line-height: 46px;
        @include mq('tablet') {
            line-height: 60px;
        }
    }
    .pink-button {
        line-height: 54px;
    }
    .white-popup .options a {
        line-height: 42px;

        @include mq('tablet') {
            line-height: 52px;
        }
    }
}

.iOS.Safari,
.iOS.iOSSafari {
    .small-padding {
        @include mq('tablet-wide', max) {
            display: inline-block;
            height: 30px;
            line-height: 26px;
            padding-bottom: 8px;
        }
    }
}

.Chrome:not(.Mac-OS) {
    .button-wrapper .button {
        .text {
            @include mq('tablet') {
                position: relative;
                top: -2px;
            }
        }
    }
}

.switch-container {
    margin-left: -6px;

    @include mq('md') {
        padding-left: 16px;
    }
}
