#sections section {
    &#causes-section {
        background: #03a2b4;
        background: linear-gradient(#03a2b4, #0593b6);

        .heading {
            text-align: center;
        }

        .flex-wrapper {
            .box {
                &.left-side {
                    margin-bottom: 30px;

                    @include mq('tablet') {
                        margin-bottom: 40px;
                    }

                    @include mq('md') {
                        margin-bottom: 0;
                    }

                    .icon-head {
                        width: 202px;
                        height: 250px;

                        @include mq('tablet') {
                            width: 291px;
                            height: 360px;
                        }

                        @include mq('md') {
                            width: 315px;
                            height: 390px;
                        }

                        svg {
                            width: 202px;
                            height: 250px;

                            @include mq('tablet') {
                                width: 291px;
                                height: 360px;
                            }

                            @include mq('md') {
                                width: 315px;
                                height: 390px;
                            }
                        }
                    }
                }

                &.right-side {
                    @include mq('tablet') {
                        margin-left: 40px;
                    }

                    @include mq('md') {
                        margin-left: 65px;
                    }

                    .heading {
                        text-align: left;
                    }
                }
            }
        }
    }
}


.IE,
.Edge,
.Safari,
.iOS .iOSSafari,
.Mac-OS.Safari,
.iOSSafari {
    #sections #causes-section {
        min-height: 100vh;
        #causes-popup.active {
            top: 0 !important;
            position: absolute;
            min-height: 100vh;
        }
    }
}

.IE {
    #causes-popup {
        transition: none!important;
    }
}

.Mac-OS.Safari,
.Chrome.Mac-OS {
    #sections section#causes-section {
        @media only screen and (min-width: 768px) and (max-width: 990px) {
            min-height: 100vh;
            height: initial;
        }
    }
}
