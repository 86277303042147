#sections section {
    &#find-endo {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        background-color: #B1AFAF;
        background-image: url('../images/cpp-desktop-bootstrap3-1170px_03.jpg');
        background-size: cover;
        background-position: 60% center;
        background-repeat: no-repeat;

        @include mq('tablet', max) {
            background-image: url('../images/bg-image-mobile.jpg');
            background-size: 100%;
            background-position: center top;
        }

        .container {
            @include mq('tablet', max) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                .box {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            &.gray-bg {
                padding: 30px 4px;
                margin-top: 80%;
                background-color: #b0aeae;
            }
        }

        .flex-wrapper {
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            height: 100%;

            .box {
                height: 100%;
                width: 100%;
                color: #ffffff;
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 50%;

                @include mq('tablet') {
                    width: 50%;
                    padding-left: 40px;
                }

                &.left-side {
                    padding-top: 36px;
                    padding-bottom: 43px;
                    width: 58%;

                    @include mq('tablet') {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    @include mq('lg', min) {
                        padding-right: 40px;
                    }
                    @include mq('phone-wide', max) {
                        padding-top: 20%;
                    }
                    @include mq('phone-small', max) {
                        padding-top: 30%;
                    }

                    .container-half {
                        margin: 0;
                        float: right;
                    }
                }

                .heading {
                    display: flex;
                    align-items: center;
                    svg {
                        @include mq('lg', min) {
                            margin-right: 30px;
                        }
                        @include mq('lg', max) {
                            max-height: 80px;
                        }
                    }

                    .superscript {
                        vertical-align: text-top;
                    }
                }

                .description {
                    @include mq('lg', min) {
                        width: 80%;
                    }
                    &.flex {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding-left: 27px;
                        ul {
                            &.left {
                                @include mq('tablet', max) {
                                    padding-left: 40px;
                                }
                            }
                            &.right {
                                padding-left: 15px;
                            }
                        }
                    }
                }

                ul {
                    list-style: none;

                    li {
                        margin-top: 3px;
                        list-style-type: disc;
                    }
                }
            }
        }

        #find-endo-popup {
            transition: right 0.5s ease-out;
            position: fixed;
            top: 0;
            right: -100%;
            width: 100%;
            height: 100vh;
            background-color: #ffffff;
            color: #00b0b3;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 998;
            padding-top: 20px;
            padding-bottom: 56px;

            @include mq('tablet') {
                padding-top: 50px;
                padding-bottom: 50px;
                height: 100%;
            }

            @include mq('tablet-wide') {
                max-height: 100vh;
            }

            &.active {
                right: 0;
            }

            .container {
                position: relative;
            }

            .close-popup {
                position: absolute;
                top: -20px;
                right: 0;
                width: 100%;
                height: 50px;
                cursor: pointer;
                background: #ffffff;
                padding: 15px;
                z-index: 99999;
                text-align: right;

                @include mq('tablet') {
                    padding: 0;
                    width: 90px;
                    height: 40px;
                }


                &:hover {
                    .fill-color-purple {
                        fill: $buttonHover;
                    }
                    .text {
                        color: $buttonHover;
                    }
                }

                .text {
                    @include generalTransition;
                    display: inline-block;
                    margin-right: 4px;
                    color: $colorPurple;
                    font-size: 15px;
                    letter-spacing: 0.3px;
                    vertical-align: middle;

                    @include mq('tablet') {
                        margin-right: 10px;
                        font-size: 20px;
                        letter-spacing: 0.4px;
                    }
                }

                .icon {
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    vertical-align: middle;

                    @include mq('tablet') {
                        width: 35px;
                        height: 35px;
                    }

                    svg {
                        width: 26px;
                        height: 26px;

                        @include mq('tablet') {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }

            .flex-wrapper {
                padding-top: 50px;
                padding-bottom: 56px;

                .image-wrapper {
                    .image {
                        display: block;
                        height: 100%;
                        margin: 0 auto;
                        @include mq('md') {
                            width: 173px;
                        }
                    }
                }

                .text-box {
                    @include mq('tablet') {
                        padding: 0 20px;
                    }

                    .heading {

                        @include mq('phone-wide', max) {
                            font-size: 25px;
                            margin-left: auto;
                            margin-right: auto;
                            text-align: center;
                        }

                        @include mq('tablet') {
                            text-align: left;
                        }
                    }
                }
                .pink-button {
                    height: auto;
                    float: left;
                    line-height: 28px;
                    padding: 10px 40px;
                    width: auto;
                    border-radius: 32px;
                    text-align: center;
                    @include mq('phone-wide', max) {
                        font-size: 15px;
                        line-height: 22px;
                        padding: 10px 30px;
                        margin-left: auto;
                        margin-right: auto;
                        float: none;
                        display: block;
                    }
                    @include mq('phone', max) {
                        padding: 10px;
                    }
                }
            }
        }
    }
}

.IE,
.Edge,
.Safari,
.iOSSafari {
    #sections #find-endo {
        min-height: 100vh;
        #find-endo-popup.active {
            top: 0 !important;
            position: absolute;
            min-height: 100vh;
        }
    }
}
.IE {
    #find-endo-popup {
        transition: none!important;
    }
}
.Mac-OS.Chrome,
.iOSSafari,
.Safari {
    #sections section#find-endo #find-endo-popup .flex-wrapper .pink-button {
        padding: 14px 26px 10px 26px;
        line-height: 24px;
    }
}


