@mixin fontWeight($args) {
    @if $args == 'light' {
        font-weight: 300;
    }
    @if $args == 'regular' {
        font-weight: 400;
    }
    @if $args == 'semi-bold' {
        font-weight: 600;
    }
    @if $args == 'bold' {
        font-weight: 700;
    }
}

@mixin transition($args) {
    transition: $args;
}

@mixin generalTransition {
    @include transition(all .3s ease);
}

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin innerFull {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%visuallyhidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}
