#website-header {
    width: 100%;

    .menu-trigger-wrapper {
        position: fixed;
        width: 100%;
        top: 35px;
        left: 0;
        z-index: 999;

        @include mq('tablet') {
            top: 32px;
        }

        @include mq('md') {
            display: flex;
        }

        .fluid-space {
            display: none;

            @include mq('md') {
                display: block;
                flex: 1;
                height: 1px;
            }
        }

        .menu-trigger-container {
            position: relative;
            width: 100%;

            .menu-trigger {
                width: 37px;
                height: 37px;
                border-radius: 50%;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 15px;
                z-index: 999;
                opacity: 0;

                &.visible {
                    opacity: 1;
                }

                @include mq('tablet') {
                    width: 47px;
                    height: 47px;
                }

                @include mq('md', max) {
                    left: 15px !important;
                }

                &:hover {
                    svg {
                        path {
                            @include mq('tablet-wide') {
                                fill: $buttonHover;
                            }
                        }
                    }
                }

                svg {
                    width: 37px;
                    height: 37px;

                    path {
                        @include generalTransition;
                        fill: #ffffff;
                    }

                    @include mq('tablet') {
                        width: 47px;
                        height: 47px;
                    }

                    &.icon-close {
                        display: none;
                        path {
                            fill: $button;
                        }
                    }

                }

                &.active {
                    svg {
                        &.icon-open {
                            display: none;
                        }

                        &.icon-close {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .menu-wrapper {
        transition: left 0.5s ease-in;
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: -120%;
        z-index: 99;

        &.active {
            transition: left 0.5s ease-out;
            left: 0;

            @include mq('tablet', max) {
                background-color: #ffffff;
                box-shadow: 1px -6px 12px 1px rgba(69, 69, 69, 0.61);
                overflow-x: hidden;
                overflow-y: auto;
            }

            .menu-inner {
                .menu-container {
                    z-index: 99;
                    padding-bottom: 30px;
                }
            }
        }

        .navigation {
            padding-left: 5px;
            padding-right: 30px;
            width: 100%;

            @include mq('tablet') {
                padding-left: 0;
            }

            @include mq('md', max) {
                padding: 15px !important;
            }

            .menu-item {
                a {
                    float: left;
                }
            }
        }

        .menu-inner {
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            background-color: #ffffff;

            @include mq('tablet') {
                background-color: #ffffff;
                box-shadow: 1px -6px 12px 1px rgba(69, 69, 69, 0.61);
            }

            @include mq('md') {
                width: 45%;
            }

            @include mq('desktop-wide') {
                width: 50%;
            }

            .menu-container {
                @include generalTransition;
                position: absolute;
                top: 90px;
                left: 0;
                right: 0;
                margin: auto;
                padding: 0 0 30px;
                z-index: -1;

                @include mq('tablet') {
                    top: 126px;
                    margin-left: 0;
                }

                @include mq('md') {
                    padding: 0;
                    width: 45%;
                }

                @include mq('desktop-wide') {
                    width: 50%;
                }
            }
        }

        .main-menu {
            .menu-item {
                a {
                    display: block;
                    color: $button;
                    margin: 5px 0;
                    font-size: 21px;
                    line-height: 1.5;

                    @include mq('tablet') {
                        margin: 10px 0;
                        font-size: 26px;
                    }

                    @include mq('desktop-wide') {
                        margin: 15px 0;
                        font-size: 28px;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        color: $buttonHover;
                    }
                }
            }
        }

        .share-menu {
            margin-top: 30px;

            @include mq('tablet') {
                margin-top: 55px;
            }

            @include mq('md') {
                margin-top: 40px;
            }

            .title {
                display: block;
                font-size: 18px;
                color: #00b0b3;
                margin-bottom: 10px;

                @include mq('tablet') {
                    font-size: 22px;
                }

                @include mq('md') {
                    font-size: 24px;
                }
            }

            .menu-item {
                display: inline-block;
                margin-left: 27px;
                margin-right: -4px;

                @include mq('md') {
                    margin-left: 20px;
                }

                &.first {
                    margin-left: 0;
                }

                .icon {
                    display: block;
                    width: 44px;
                    height: 44px;

                    @include mq('md') {
                        width: 32px;
                        height: 32px;
                    }

                    svg {
                        width: 44px;
                        height: 44px;

                        @include mq('md') {
                            width: 32px;
                            height: 32px;
                        }

                        path {
                            @include generalTransition;
                            fill: $button;
                        }
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        svg {
                            path {
                                fill: $buttonHover;
                            }
                        }
                    }
                }
            }
        }
    }
}
