/* Breakpoints */
$breakpoints: (
    "phone-small":      360px,
    "phone":            400px,
    "phone-wide":       480px,
    "phablet":          560px,
    "tablet-small":     641px,
    "tablet":           768px,
    "md":               992px,
    "tablet-wide":      1024px,
    "tablet-wide-1":    1025px,
    "lg-small":         1100px,
    "lg":               1200px,
    "desktop":          1248px,
    "desktop-small":    1320px,
    "desktop-wide":     1440px,
    "desktop-wide-hd":  1600px,
    "desktop-fhd":      1920px,
    "desktop-wqhd":     2499px
);

/* Breakpoints Bootstrap Layout */
$breakpoint-lg : 1200px;
$breakpoint-md : 991px;
$breakpoint-sm : 768px;
$breakpoint-xs : 640px;

$errorColor: #a94442;

$button: #a70063;
$buttonHover: #dca310;

$colorLime: #d0dd28;
$colorPink: #ef6399;
$colorPurple: #a70063;
