#sections section {
    &#facts-section {
        background: #00b1b3;
        background: linear-gradient(#00b1b3, #03a2b4);

        .top-block {
            margin-bottom: 30px;
            h2 {
                text-align: center;
            }

            @include mq('tablet') {
                margin-bottom: 40px;
            }

            @include mq('md') {
                margin-bottom: 87px;
            }

            .description {
                @include mq('phone-small') {
                    padding: 0 6%;
                }
                @include mq('phone') {
                    padding: 0 10%;
                }
                @include mq('tablet-small') {
                    padding: 0 2%;
                }
                @include mq('tablet') {
                    padding: 0;
                    text-align: center;
                }
            }

        }

        .bottom-block {
            .box {
                &.left-side {
                    text-align: center;

                    @include mq('md', max) {
                        margin-bottom: 44px;
                    }

                    .icon-wrapper {
                        @include mq('md', max) {
                            display: block;
                        }
                    }
                    .description {
                        position: relative;
                        margin: 12px 0;
                        vertical-align: middle;

                        &:first-child {
                            @include mq('phone-wide', min) {
                                text-align: left;
                            }
                        }
                        &:last-child {
                            @include mq('phone-wide', min) {
                                text-align: right;
                            }
                        }
                    }
                }

                &.right-side {
                    width: 220px;
                    margin: 0 auto;

                    @include mq('md') {
                        width: auto;
                        margin: 0 0 0 80px;
                    }

                    .description {
                        margin-bottom: 5px;
                    }

                    .animate-progress-line {
                        transition: opacity 0.3s ease;
                        opacity: 0;
                        position: relative;
                        margin-top: 12px;
                        line-height: 45px;
                        height: 45px;
                        width: 220px;
                        padding-left: 13px;
                        @include mq('md') {
                            padding-left: 17px;
                        }

                        @include mq('md') {
                            margin-top: 16px;
                            line-height: 60px;
                            height: 60px;
                            width: 300px;
                        }

                        .description {
                            position: relative;
                            z-index: 2;
                        }

                        .line {
                            transition: width 0.5s ease-out;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 0;
                            height: 100%;
                            box-shadow: 0 0 15.5px 0.5px rgba(0, 103, 108, 0.61);

                            &.girl {
                                background-color: $colorPink;
                            }
                            &.boy {
                                background-color: $colorLime;
                            }
                        }

                        &.inview {
                            opacity: 1;
                            .line {
                                &.girl {
                                    width: 200px;

                                    @include mq('md') {
                                        width: 293px;
                                    }
                                }
                                &.boy {
                                    width: 158px;

                                    @include mq('md') {
                                        width: 211px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .box-white-people {
            height: 40px;
            overflow: hidden;
            margin: 10px 0;
            position: relative;
            width: 100%;
            top: -2px;

            @include mq('phone-wide', min) {
                position: absolute;
                margin: 0;
                top: -6px;
            }

            @include mq('md', min) {
                top: -2px;
            }

            img {
                width: 28px;
                opacity: 0;
            }

            @include mq('phone-wide', max) {
                width: 100%;
                max-width: 280px;
                display: block;
                float: none;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                justify-content: center;
            }

            &.first {
                right: 0;

                @include mq('phone-wide', min) {
                    width: 64%;
                }
                @include mq('tablet', min) {
                    width: 70%;
                }
                @include mq('md', min) {
                    width: 74%;
                }
                .inner {
                    float: right;
                }

            }
            &.second {
                left: 0;
                @include mq('tablet') {
                    width: 80%;
                }
                .inner {
                    float: left;
                }

            }
            overflow-x: hidden;
        }
        .inner {
            width: 100%;
            height: 40px;
            overflow-y: hidden;
            @include mq('phone-wide', max) {
                float: none;
            }
        }

        .box-white-people.first img {
            float: right;
        }

        .box-white-people.second img {
            float: left;
        }
    }
}

.Mac-OS.Chrome,
.iOS.iOSSafari,
.Mac-OS.Safari,
.Mac-OS.Safari {
    #sections section#facts-section .box-white-people {
        top: 4px;
    }
    #sections section#facts-section .bottom-block .box.right-side .animate-progress-line .description {
        top: -6px;
    }
}

.iOSSafari {
    #sections section#facts-section .box-white-people.second {
        top: -10px;
    }
}

.IE {
  /*  #sections section#facts-section .bottom-block .box.left-side {
        border: 1px solid red;
        float: left;
        max-width: 500px;
    }
    #sections section#facts-section .bottom-block .box.right-side {
        border: 1px solid yellow;
        float: left;
    }*/
}
