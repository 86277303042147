#sections section {
    &#how-treatments-work {
        background: #a70063;
        .heading {
            text-align: center;
        }

        .flex-wrapper {
            .box {
                &.left-side {
                    margin-bottom: 26px;

                    @include mq('tablet') {
                        margin-bottom: 40px;
                    }

                    @include mq('md') {
                        margin-bottom: 0;
                    }

                    .icon-stick {
                        height: 250px;
                        @include mq('md') {
                            width: 350px;
                            height: 350px;
                        }

                        img {
                            width: 318px;
                            height: 250px;


                            @include mq('md') {
                                width: 321px;
                                height: 350px;
                            }
                        }
                    }
                }

                &.right-side {
                    @include mq('tablet') {
                        margin-left: 40px;
                    }

                    @include mq('md') {
                        margin-left: 65px;
                    }

                    .heading {
                        text-align: left;
                    }
                    .description {
                        @media only screen and (min-width: 767px) and (max-width: 991px) {
                            width: 74%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
    #brain-circles {
        .brain-skull {
            position: relative;
            width: 314px;
            img {
                position: relative;
                z-index: 3;
            }

            .white-circle {
                border: 1px solid #FFFFFF;
                border-radius: 50%;
                transition: opacity 0.6s ease;
                position: absolute;
                top: 0;
                opacity: 0;
            }
            .white-circle:nth-child(2) {
                left: 15px;
                top: 15px;
            }
            .white-circle:first-child {
                left: 30px;
                top: 30px;
            }
        }
        .circle-holder {
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 9;
            width: 90px;
            height: 90px;
            top: 111px;
            right: 82px;
            @include mq('tablet', max) {
                top: 66px;
                right: 92px;
            }
            @include mq('md', max) {
                top: 68px;
                right: 90px;
            }
        }
    }
}

.IE {
    #sections section#find-endo .flex-wrapper .box.left-side .description{
        width: 500px;
        float: right;
    }
}