//used for the "What is CPP" blue bubble text for Safari
//.safari-padding-class{
//    line-height:'0px';
//    padding-top:'1px';
////    paddingLeft: '20px';
////    paddingRight: '20px';
//}

.hero-image-wrapper {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    will-change: transform;
    overflow: hidden;

    .hero-image {
        width: 100%;
        height: 100%;
        background-image: url('../images/home-hero-mobile.jpg');
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        overflow: hidden;
        box-shadow: 0 17px 30px -17px rgba(1,52,95,0.3);

        @include mq('md') {
            background-image: url('../images/home-hero-desktop-1440.jpg');
            background-position: bottom right;

            @media only screen and (min-height: 768px) {
                background-image: url('../images/home-hero-mobile.jpg');
                background-position: center top;
            }
        }

        @include mq('tablet-wide') {
            background-image: url('../images/home-hero-desktop-1440.jpg');
            background-position: bottom right;

            @media only screen and (min-height: 768px) {
                background-image: url('../images/home-hero-mobile.jpg');
                background-position: center top;
            }
        }

        @include mq('lg') {
            background-image: url('../images/home-hero-desktop-1600.jpg');
            background-position: bottom right;

            @media only screen and (min-height: 860px) {
                background-image: url('../images/home-hero-desktop-1920.jpg');
                background-position: bottom right;
            }

            @media only screen and (min-height: 920px) {
                background-image: url('../images/home-hero-mobile.jpg');
                background-position: center top;
            }
        }

        @include mq('desktop') {
            background-image: url('../images/home-hero-desktop-1920.jpg');
            background-position: bottom right;

            @media only screen and (min-height: 920px) {
                background-image: url('../images/home-hero-mobile.jpg');
                background-position: center top;
            }
        }

        @include mq('desktop-wide-hd') {
            background-image: url('../images/home-hero-desktop-1920.jpg');
            background-position: bottom right;
        }

        .hero-container {
            position: relative;
            margin-left: 0;
            padding: 0;
            width: 100%;
            min-width: initial;

            @include mq('md') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 35%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 32%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 26%;
                }
                @media only screen and (min-height: 768px) {
                    width: 30%;
                }
            }

            @include mq('tablet-wide') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 38%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 35%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 30%;
                }
                @media only screen and (min-height: 768px) {
                    width: 30%;
                }
            }

            @include mq('lg-small') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 47%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 40%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 36%;
                }
                @media only screen and (min-height: 768px) {
                    width: 30%;
                }
            }

            @include mq('lg') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 49%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 46%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 41%;
                }
                @media only screen and (min-height: 768px) and (max-height: 799px) {
                    width: 39%;
                }
                @media only screen and (min-height: 800px) and (max-height: 859px) {
                    width: 36%;
                }
                @media only screen and (min-height: 860px) and (max-height: 919px) {
                    width: 43%;
                }
                @media only screen and (min-height: 920px) {
                    width: 30%;
                }
            }

            @include mq('desktop') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 56%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 55%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 52%;
                }
                @media only screen and (min-height: 768px) and (max-height: 799px) {
                    width: 52%;
                }
                @media only screen and (min-height: 800px) and (max-height: 859px) {
                    width: 47%;
                }
                @media only screen and (min-height: 860px) and (max-height: 919px) {
                    width: 43%;
                }
                @media only screen and (min-height: 920px) {
                    width: 30%;
                }
            }

            @include mq('desktop-small') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 55%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 57%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 56%;
                }
                @media only screen and (min-height: 768px) and (max-height: 799px) {
                    width: 53%;
                }
                @media only screen and (min-height: 800px) and (max-height: 859px) {
                    width: 50%;
                }
                @media only screen and (min-height: 860px) and (max-height: 919px) {
                    width: 46%;
                }
                @media only screen and (min-height: 920px) {
                    width: 30%;
                }
            }

            @include mq('desktop-wide') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 56%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 55%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 56%;
                }
                @media only screen and (min-height: 768px) and (max-height: 799px) {
                    width: 50%;
                }
                @media only screen and (min-height: 800px) and (max-height: 859px) {
                    width: 53%;
                }
                @media only screen and (min-height: 860px) and (max-height: 919px) {
                    width: 54%;
                }
                @media only screen and (min-height: 920px) {
                    width: 30%;
                }
            }

            @include mq('desktop-wide-hd') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 56%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 57%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 56%;
                }
                @media only screen and (min-height: 768px) and (max-height: 799px) {
                    width: 57%;
                }
                @media only screen and (min-height: 800px) and (max-height: 859px) {
                    width: 56%;
                }
                @media only screen and (min-height: 860px) and (max-height: 919px) {
                    width: 58%;
                }
                @media only screen and (min-height: 920px) and (max-height: 979px) {
                    width: 56%;
                }
                @media only screen and (min-height: 980px) and (max-height: 1079px) {
                    width: 52%;
                }
                @media only screen and (min-height: 1080px) and (max-height: 1199px) {
                    width: 44%;
                }
                @media only screen and (min-height: 1200px) and (max-height: 1299px) {
                    width: 45%;
                }
                @media only screen and (min-height: 1300px) and (max-height: 1439px) {
                    width: 36%;
                }
                @media only screen and (min-height: 1440px) {
                    width: 30%;
                }
            }

            @include mq('desktop-fhd') {
                width: 50%;

                @media only screen and (min-height: 640px) and (max-height: 679px) {
                    width: 56%;
                }
                @media only screen and (min-height: 680px) and (max-height: 719px) {
                    width: 57%;
                }
                @media only screen and (min-height: 720px) and (max-height: 767px) {
                    width: 57%;
                }
                @media only screen and (min-height: 768px) and (max-height: 799px) {
                    width: 57%;
                }
                @media only screen and (min-height: 800px) and (max-height: 859px) {
                    width: 56%;
                }
                @media only screen and (min-height: 860px) and (max-height: 919px) {
                    width: 58%;
                }
                @media only screen and (min-height: 920px) and (max-height: 979px) {
                    width: 57%;
                }
                @media only screen and (min-height: 980px) and (max-height: 1079px) {
                    width: 57%;
                }
                @media only screen and (min-height: 1080px) and (max-height: 1199px) {
                    width: 55%;
                }
                @media only screen and (min-height: 1200px) and (max-height: 1299px) {
                    width: 51%;
                }
                @media only screen and (min-height: 1300px) and (max-height: 1439px) {
                    width: 45%;
                }
                @media only screen and (min-height: 1440px) {
                    width: 42%;
                }
            }

            @include mq('desktop-wqhd') {
                @media only screen and (min-height: 1080px) {
                    width: 58%;
                }
            }
        }

        .inner {
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;

            @include mq('phone-wide') {
                top: 45%;
            }

            @include mq('tablet') {
                top: 53%;
            }

            @include mq('md', max) {
                max-width: 90%;
            }

            @include mq('md') {
                top: 30%;
            }

            @include mq('tablet-wide') {
                top: 30%;

                @media only screen and (min-height: 839px) {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .heading {
                @include generalTransition;
                display: inline-block;
                position: relative;
                padding: 20px 27px 14px 20px;
                font-size: 40px;
                line-height: 46px;
                text-align: center;
                color: #ffffff;
                background-color: #00b1b3;
                border-radius: 24px;
                margin: 0 auto;

                @include mq('tablet') {
                    font-size: 64px;
                    line-height: 56px;
                    border-radius: 28px;
                    padding: 28px 27px 14px 20px;
                }

                @include mq('md') {
                    font-size: 64px;
                    line-height: 64px;
                    padding: 28px 32px 21px 25px;
                    border-radius: 26px;

                    @media only screen and (min-height: 560px) and (max-height: 679px) {
                        font-size: 50px;
                        line-height: 50px;
                    }
                    @media only screen and (min-height: 680px) and (max-height: 719px) {
                        font-size: 40px;
                        line-height: 40px;
                    }
                    @media only screen and (min-height: 720px) and (max-height: 767px) {
                        font-size: 32px;
                        line-height: 32px;
                        padding: 20px 25px 15px 19px;
                        border-radius: 30px;
                    }
                    @media only screen and (min-height: 768px) {
                        font-size: 34px;
                        line-height: 34px;
                        padding: 22px 26px 16px 20px;
                        border-radius: 26px;
                    }
                }

                @include mq('lg') {
                    font-size: 72px;
                    line-height: 72px;
                    padding: 28px 32px 21px 25px;
                    border-radius: 26px;

                    @media only screen and (min-height: 768px) {
                        font-size: 58px;
                        line-height: 52px;
                        padding: 32px 30px 20px 28px;
                        border-radius: 30px;
                    }

                    @media only screen and (min-height: 920px) {
                        font-size: 52px;
                        line-height: 46px;
                        padding: 28px 26px 20px 22px;
                        border-radius: 26px;
                    }
                }

                @include mq('desktop-wide') {
                    font-size: 87px;
                    line-height: 80px;
                    padding: 32px 34px 18px 32px;

                    @media only screen and (min-height: 920px) {
                        font-size: 52px;
                        line-height: 46px;
                        padding: 28px 26px 20px 22px;
                        border-radius: 26px;
                    }
                }

                @include mq('desktop-wide-hd') {
                    font-size: 87px;
                    line-height: 80px;
                    padding: 32px 34px 18px 32px;

                    @media only screen and (min-height: 920px) {
                        font-size: 87px;
                        line-height: 80px;
                        padding: 32px 34px 18px 32px;
                    }

                    @media only screen and (min-height: 1200px) {
                        font-size: 68px;
                        line-height: 60px;
                        padding: 30px 28px 18px 26px;
                    }
                }

                @include mq('desktop-fhd') {
                    font-size: 87px;
                    line-height: 80px;
                    padding: 32px 34px 18px 32px;
                }

                @include mq('desktop-wqhd') {
                    font-size: 98px;
                    line-height: 90px;
                    padding: 40px 38px 24px 34px;
                }

                .icon-comment-line {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 30px;
                    height: 28px;

                    svg {
                        position: absolute;
                        bottom: 2px;
                        right: -16px;
                        width: 30px;
                        height: 28px;

                        path {
                            fill: #00b1b3;
                        }
                    }
                }
            }

            .under-heading-box {
                text-align: center;
                margin: auto;
            }

            .description {
                @include generalTransition;
                margin-top: 28px;
                font-size: 25px;
                text-align: center;
                color: #f3f3f3;

                @include mq('tablet') {
                    margin-top: 34px;
                    font-size: 30px;
                }

                @include mq('md') {
                    margin-top: 38px;
                    font-size: 26px;

                    @media only screen and (min-height: 560px) and (max-height: 679px) {
                        font-size: 24px;
                    }
                    @media only screen and (min-height: 680px) and (max-height: 719px) {
                        font-size: 22px;
                    }
                    @media only screen and (min-height: 720px) and (max-height: 767px) {
                        font-size: 19px;
                    }
                    @media only screen and (min-height: 768px) {
                        font-size: 20px;
                    }
                }

                @include mq('lg') {
                    font-size: 34px;

                    @media only screen and (min-height: 768px) {
                        font-size: 26px;
                    }
                }

                @include mq('desktop-wide') {
                    margin-top: 46px;
                    font-size: 34px;

                    @media only screen and (min-height: 768px) {
                        font-size: 38px;
                    }

                    @media only screen and (min-height: 920px) {
                        font-size: 28px;
                    }
                }

                @include mq('desktop-wide-hd') {
                    font-size: 38px;
                }

                @include mq('desktop-wqhd') {
                    margin-top: 56px;
                    font-size: 46px;
                }

                .superscript {
                    font-size: 12px;

                    @include mq('tablet') {
                        font-size: 14px;
                    }

                    @include mq('md') {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}


.Mac-OS.Chrome,
.Mac-OS.Safari {
    .hero-image-wrapper .hero-image .inner .heading {
        padding: 28px 27px 16px 20px;

        @include mq('tablet') {
            padding: 36px 32px 18px 25px;
        }

        @include mq('md') {
            padding: 54px 53px 32px 38px;
        }
        @include mq('desktop-wide') {
            padding: 52px 53px 18px 38px;
        }
        @media only screen and (min-width: 320px) and (max-height: 700px) {
            padding: 36px 27px 16px 20px;
        }
    }
}

.iOSSafari {
    .hero-image-wrapper .hero-image .inner .heading {
        @include mq('phone-wide', max) {
            padding-top: 30px;
        }
        @include mq('phone-wide') {
            padding-top: 40px;
        }
    }
}
