#sections section {
    &#discussion-guide {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
		@include mq('tablet', min) {
			min-height: 650px;
		}

        .container {
            @include mq('tablet', max) {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                .box {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            @include mq('desktop') {
                width: 542px ;
            }
            @include mq('desktop-wide') {
                width: 685px;
            }

        }

        .flex-wrapper {
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            height: 100%;

            .box {
                height: 100%;
                width: 100%;
                color: #ffffff;
				display: flex;
				align-items: flex-start;
				justify-content: center;

                @include mq('tablet') {
                    width: 50%;
                    padding-left: 40px;
                }
				@include mq('tablet', max) {
                    padding: 10% 0 !important;
                }
				@include mq('tablet', min) {
					min-height: 650px;
                }

                &.left-side {
                    background-color: #0885b7;
					
                    @include mq('tablet') {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .container-half {
                        margin: 0;
                        float: right;
                    }
                    .description {
                        @include mq('desktop-wide') {
                            width: 82%;
                            margin: 0 auto;
                        }
                    }
                }

                &.right-side {
                    background-color: #00b0b3;

                    @include mq('tablet') {
                        padding-right: 0;
                        padding-left: 0;
                    }

                    @include mq('lg') {
                        padding-left: 50px;
                    }

                    .container-half {
                        margin: 0;
                        float: left;
                        padding-left: 10%;
                        padding-right: 10%;
                    }
                }
				
				.container {
					height: auto;
				}
				

                .heading {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    @include mq('tablet', max) {
                        margin-bottom: 6px;
                    }

                    svg {
                        @include mq('lg', min) {
                            margin-right: 30px;
                        }
                        @include mq('lg', max) {
                            max-height: 80px;
                        }
                    }

                    .superscript {
                        vertical-align: text-top;
                    }
                }

                .description {
                    text-align: center;
                }

                .small-description {
                    margin-top: 20px;
                    font-size: 17px;
                    color: #ffffff;
                    text-align: center;

                    @include mq('tablet') {
                        margin-top: 40px;
                    }
                }

                ul {
                    list-style: none;

                    li {
                        margin-top: 3px;
                        list-style-type: disc;
                    }
                }
            }
        }
    }
    .container-half {
        @include mq('tablet', max) {
            padding-left: 30%;
            margin-right: 0;
        }
        .description {
            &:not(.flex) {
                ul {
                    @include mq('lg', max) {
                        padding-left: 10%;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}


.iOSSafari {
    #sections section#discussion-guide .flex-wrapper {
        display: block;
    }
    #sections section#discussion-guide .flex-wrapper .box.right-side {
        @include mq('tablet') {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
